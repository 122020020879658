import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const fetchCategories = async (token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.get(`${apiBaseUrl}/api/category/`);
    return data;
  } catch (error) {
    console.error(
      "categoryService:fetchCategories() Error: ",
      error.response.statusText
    );
    return error.response.statusText;
  }
};

const addCategory = async (category, token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.post(`${apiBaseUrl}/api/category`, category);
    return { data: data, isAdded: true, error: null };
  } catch (error) {
    console.error(
      "categoryService:addCategory() Error: ",
      error.response.statusText
    );
    return { data: null, isAdded: false, error: error.response.statusText };
  }
};

const deleteCategory = async (catId, token) => {
  try {
    
    const { data } = await axios.delete(`${apiBaseUrl}/api/category/${catId}/`);
    return {
      isDeleted: true,
      error: null,
    };
  } catch (error) {
    console.error(
      "categoryService:deleteCategory()  Error: ",
      error.response.statusText
    );
    return {
      isDeleted: false,
      error: error.response.statusText,
    };
  }
};

const updateCategory = async (category, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.put(
      `${apiBaseUrl}/api/category/${category.catId}/`,
      category,
      config
    );
    return {
      data: data,
      isUpdated: true,
      error: null,
    };
  } catch (error) {
    console.error(
      "categoryService:updateCategory()  Error: ",
      error.response.statusText
    );
    return {
      data: null,
      isUpdated: false,
      error: error.response.statusText,
    };
  }
};

const getCategoryById = async (catId, token) => {
  try {
      const { data } = await axios.get(`${apiBaseUrl}/api/category/${catId}`);
      return data;
  } catch (error) {
      console.error(
      "jobService:getJobsById()  Error: ",
      error.response.statusText
      );
      return error.response.statusText;
  }
  }


const categoriesService = {
  addCategory,
  fetchCategories,
  updateCategory,
  deleteCategory,
  getCategoryById
};
export default categoriesService;
