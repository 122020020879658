import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

export const fetchContacts = async () => {
    try {
        const { data } = await axios.get(`${apiBaseUrl}/api/contact/all`);
        return data;
    } catch (error) {
        console.error(
            "contactService:fetchContacts() Error: ",
            error.response.statusText
        );
        return error.response.statusText;
    }
}

export const addContact = async (contact) => {
    try {
        const { data } = await axios.post(`${apiBaseUrl}/api/contact/create`, contact);
        return { data: data, isAdded: true, error: null };
    } catch (error) {
        console.error(
            "contactService:addContact() Error: ",
            error.response.statusText
        );
        return { data: null, isAdded: false, error: error.response.statusText };
    }
}

// export { fetchContacts, addContact };