import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ComingSoon.css";
// import { Navbar } from "react-bootstrap";
import Card from "../../components/Card";
import Carousel from "../../components/Carousel";
import { fetchQuizzes } from "../../actions/quizzesActions";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("jwtToken"));
  const quizzesReducer = useSelector((state) => state.quizzesReducer);
  const [quizzes, setQuizzes] = useState(quizzesReducer.quizzes);
  const navigate = useNavigate();

  useEffect(() => {
    // if (quizzes.length !== 0) {
    fetchQuizzes(dispatch, token).then((data) => {
      setQuizzes(data.payload);
    });
    // }
    // setQuizzes(mockQuizzes);
  }, []);
  return (
    <>
      <div
        className="min-h-screen bg-cover bg-center"
        style={{
          backgroundImage:
            "url(https://assets.leverageedu.com/about-us/our-team-bg-web.png)",
        }}
      >
        <div className="banner-container">
          <div className="logo-holder logo-4">
            <a href="/">
              <h3>
                Sarkari <span style={{ color: "black" }}> Exams </span>Hub
              </h3>
              {/* <p>ipsum dolor sit</p> */}
            </a>
          </div>
          <div>
            <div className="text">
              <p className="text-decoration p-2">
                Crack your exam with
                <span className="main-text p-2">
                  India's Best Mock Test Series
                </span>
              </p>
            </div>
            <div className="banner-list">
              <div className="list-items flex flex-col sm:flex-row">
                <img src="images/check.png" alt="Your" className="check-img" />
                <p style={{ paddingTop: "18px" }}>Best Test Series</p>
              </div>
              <div className="list-items flex flex-col sm:flex-row">
                <img src="images/check.png" alt="Your" className="check-img" />
                <p style={{ paddingTop: "18px" }}>
                  Access to 50,000+ Mock Test
                </p>
              </div>
              <div className="list-items flex flex-col sm:flex-row">
                <img src="images/check.png" alt="Your" className="check-img" />
                <p style={{ paddingTop: "18px" }}>Daily New Test Series</p>
              </div>
            </div>
          </div>
        </div>
        {/* popular test series  */}
        <div className="w-full mx-auto pl-4 md:pl-0">
          {/* all cards */}
          <h1 className="p-4 text-center font-semibold">Popular Test Series</h1>
          <div className="w-full sm:w-3/4 grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-40 lg:grid-cols-4 mx-auto place-items-center">
            <Card quizzes={quizzes} />;
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={() => navigate("/quizzes")}
              className="hover:bg-blue-400 hover:text-white mt-4 mb-2 bg-green-500 px-4 py-2 border-2 border-black/30 rounded text-white"
            >
              Explore All Test Series
            </button>
          </div>
        </div>
        {/* carousel */}
        {/* bg-gradient-to-br from-blue-300 to-teal-50 */}
        <div className="w-full">
          <div className="w-3/4 m-auto py-4 h-auto">
            <h1 className="py-2 font-semibold text-center">
              What Our Students Say ❤️
            </h1>
            <Carousel />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
