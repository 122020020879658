export const FETCH_ADMIT_CARD_REQUEST = "FETCH_ADMIT_CARD_REQUEST";
export const FETCH_ADMIT_CARD_SUCCESS = "FETCH_ADMIT_CARD_SUCCESS";
export const FETCH_ADMIT_CARD_FAILURE = "FETCH_ADMIT_CARD_FAILURE";

export const ADD_ADMIT_CARD_REQUEST = "ADD_ADMIT_CARD_REQUEST";
export const ADD_ADMIT_CARD_SUCCESS = "ADD_ADMIT_CARD_SUCCESS";
export const ADD_ADMIT_CARD_FAILURE = "ADD_ADMIT_CARD_FAILURE";

export const DELETE_ADMIT_CARD_REQUEST = "DELETE_ADMIT_CARD_REQUEST";
export const DELETE_ADMIT_CARD_SUCCESS = "DELETE_ADMIT_CARD_SUCCESS";
export const DELETE_ADMIT_CARD_FAILURE = "DELETE_ADMIT_CARD_FAILURE";

export const UPDATE_ADMIT_CARD_REQUEST = "UPDATE_ADMIT_CARD_REQUEST";
export const UPDATE_ADMIT_CARD_SUCCESS = "UPDATE_ADMIT_CARD_SUCCESS";
export const UPDATE_ADMIT_CARD_FAILURE = "UPDATE_ADMIT_CARD_FAILURE";