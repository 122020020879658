export const FETCH_RESULT_REQUEST = "FETCH_RESULT_REQUEST";
export const FETCH_RESULT_SUCCESS = "FETCH_RESULT_SUCCESS";
export const FETCH_RESULT_FAILURE = "FETCH_RESULT_FAILURE";

export const ADD_RESULT_REQUEST = "ADD_RESULT_REQUEST";
export const ADD_RESULT_SUCCESS = "ADD_RESULT_SUCCESS";
export const ADD_RESULT_FAILURE = "ADD_RESULT_FAILURE";

export const DELETE_RESULT_REQUEST = "DELETE_RESULT_REQUEST";
export const DELETE_RESULT_SUCCESS = "DELETE_RESULT_SUCCESS";
export const DELETE_RESULT_FAILURE = "DELETE_RESULT_FAILURE";

export const UPDATE_RESULT_REQUEST = "UPDATE_RESULT_REQUEST";
export const UPDATE_RESULT_SUCCESS = "UPDATE_RESULT_SUCCESS";
export const UPDATE_RESULT_FAILURE = "UPDATE_RESULT_FAILURE";