import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import "./AdminProfilePage.css";
import Image from "react-bootstrap/Image";
import { fetchCategories } from "../../actions/categoriesActions";
import { fetchQuizzes } from "../../actions/quizzesActions";

const AdminProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginReducer = useSelector((state) => state.loginReducer);
  const user = loginReducer.user;
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  

  useEffect(() => {
    fetchCategories(dispatch, token);
  }, [dispatch]);

  useEffect(() => {
    fetchQuizzes(dispatch, token);
  }, [dispatch]);
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/login");
  }, []);

  return (
    <div className="adminProfilePage__container">
      <div className="adminProfilePage__sidebar">
        <Sidebar />
      </div>
      <div className="adminProfilePage__content"> 
            <div className="w-full md:w-1/2">
                <div className="overflow-hidden bg-white rounded-lg shadow-md">
                    <div className="flex items-center justify-center bg-gradient-to-r from-orange-400 to-pink-500 text-white">
                        <div className="text-center">
                            <img src="https://img.icons8.com/bubbles/100/000000/user.png" className="rounded-full w-100 h-70 mx-auto" />
                            <h6 className="font-semibold text-xl">{user?.name}</h6>
                            <p>{user?.role}</p>
                            <i className="feather icon-edit mt-4 text-2xl"></i>
                        </div>
                    </div>
                    <div className="p-8">
                        <h6 className="mb-5 pb-2 border-b font-semibold">Information</h6>
                        <div className="flex">
                            <div className="w-1/2">
                                <p className="mb-2 font-semibold">Email</p>
                                <h6 className="text-gray-600">{user?.email}</h6>
                            </div>
                            <div className="w-1/2">
                                <p className="mb-2 font-semibold">Phone</p>
                                <h6 className="text-gray-600">{user?.phone}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/* <Table bordered className="adminProfilePage__content--table bg-black">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{`${user.firstName} ${user.lastName}`}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{user.username}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{user.phoneNumber}</td>
            </tr>
            <tr>
              <td>Role</td>
              <td>{user.roles[0].roleName}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{`${user.active}`}</td>
            </tr>
          </tbody>
        </Table> */}
      </div>
    </div>
  );
};

export default AdminProfilePage;
