import React, { useEffect, useState } from "react";
import "./UserQuestionsPage.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { fetchQuestionsByQuiz } from "../../actions/questionsActions";
import Question from "../../components/Question";
import Loader from "../../components/Loader";
import swal from "sweetalert";
import * as quizResultConstants from "../../constants/quizResultConstants";
import { submitQuiz } from "../../actions/quizResultActions";
import { fetchQuizzes } from "../../actions/quizzesActions";
// import ReactSpinnerTimer from "react-spinner-timer";

const UserQuestionsPage = () => {
  Number.prototype.zeroPad = function () {
    return ("0" + this).slice(-2);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const quizId = urlParams.get("quizId");
  const quizTitle = urlParams.get("quizTitle");
  const quizzesReducer = useSelector((state) => state.quizzesReducer);
  const [quizzes, setQuizzes] = useState(quizzesReducer.quizzes);
  const [quiz, setQuiz] = useState(
    quizzes?.filter((q) => q.quizId == quizId)[0]
  );
  const questionsReducer = useSelector((state) => state.questionsReducer);
  const [questions, setQuestions] = useState(questionsReducer.questions);
  const token = JSON.parse(localStorage.getItem("jwtToken"));
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user.userId : null;
  const [timeRemaining, setTimeRemaining] = useState(questions.length * 2 * 60);
  let intervalId = null;
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [totalMarks, setTotalMarks] = useState(0);

  useEffect(() => {
    intervalId = setInterval(() => {
      if (timeRemaining <= 0) {
        submitQuizHandler();
      } else {
        setTimeRemaining((prev) => prev - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, [timeRemaining]);

  
  const handleOptionSelect = (questionId, selectedOption, correctOption) => {
    const isCorrect = selectedOption?.replace(/[\n\r]+/g, '') === correctOption?.replace(/[\n\r]+/g, '');
    const questionIndex = selectedAnswers.findIndex(
      (answer) => answer?.questionId === questionId
    );

    const newAnswer = {
      questionId,
      selectedOption,
      isCorrect,
    };

    if (questionIndex !== -1) {
      const updatedAnswers = [...selectedAnswers];
      updatedAnswers[questionIndex] = newAnswer;
      setSelectedAnswers(updatedAnswers);
    } else {
      setSelectedAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    }
  };

  const submitQuizHandler = () => {
    clearInterval(intervalId);

    const obtainedMarks = selectedAnswers.reduce(
      (total, answer) => (answer.isCorrect ? total + 2 : total - 0.25),
      0 // Initial value of total
    );
    
    sessionStorage.setItem("quizResult", JSON.stringify({
      totalQuestions: questions?.length,
      numberOfQuestionAnswered: selectedAnswers?.length,
      obtainedMarks, 
      quizId: quizId
    }));

    // Show success message
    swal("Quiz Submitted!", `You scored ${obtainedMarks} marks.`, "success")
      .then(() => {
        navigate(`/quiz-results/${quizId}`);
      });
  };

  useEffect(() => {
    if (quizzes.length == 0) {
      fetchQuizzes(dispatch).then((data) => {
        const temp = data.payload;
        setQuizzes(temp);
        setQuiz(temp.filter((q) => q?.quizId == quizId)[0]);
      });
    }
  }, []);

  useEffect(() => {
    fetchQuestionsByQuiz(dispatch, quizId, token).then((data) => {
      setQuestions(data.payload);
      setTimeRemaining(data.payload?.length * 2 * 60);
    });
  }, []);


  return (
    <div className="userQuestionsPage__container">
      <div className="userQuestionsPage__content">
        <h2>{`Questions : ${quizTitle}`}</h2>
        <div className="userQuestionsPage__content--options">
          <Button
            className="userQuestionsPage__content--button"
            onClick={() => submitQuizHandler()}
          >
            Submit Quiz
          </Button>
          <div className="userQuestionsPage__content--spinner">
  
            <h4 style={{ marginTop: "18px" }}>{`${parseInt(
              timeRemaining / 60
            ).zeroPad()} : ${(timeRemaining % 60).zeroPad()}`}</h4>
            Timer
          </div>
        </div>
        {questions ? (
          questions?.map((q, index) => {
            return <Question key={index} number={index + 1} question={q} handleOptionSelect={handleOptionSelect}/>;
          })
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default UserQuestionsPage;
