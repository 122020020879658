import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const fetchJobs = async (token) => {
    try {
        // const config = {
        //   headers: { Authorization: `Bearer ${token}` },
        // };
        const { data } = await axios.get(`${apiBaseUrl}/api/job/all`);
        return data;
    } catch (error) {
        console.error(
        "jobService:fetchJobs() Error: ",
        error.response.statusText
        );
        return error.response.statusText;
    }
    };

const addJob = async (job, token) => {
    try {
        // const config = {
        //   headers: { Authorization: `Bearer ${token}` },
        // };
        const { data } = await axios.post(`${apiBaseUrl}/api/job/create`, job);
        return { data: data, isAdded: true, error: null };
    } catch (error) {
        console.error(
        "jobService:addJob() Error: ",
        error.response.statusText
        );
        return { data: null, isAdded: false, error: error.response.statusText };
    }
    };

const deleteJob = async (jobId, token) => {
    try {
        
        const { data } = await axios.delete(`${apiBaseUrl}/api/job/${jobId}/`);
        return {
        isDeleted: true,
        error: null,
        };
    } catch (error) {
        console.error(
        "jobService:deleteJob()  Error: ",
        error.response.statusText
        );
        return {
        isDeleted: false,
        error: error.response.statusText,
        };
    }
    }

const updateJob = async (job, jobId, token) => {
    try {
        const config = {
        headers: { Authorization: `Bearer ${token}` },
        };
        const { data } = await axios.put(`${apiBaseUrl}/api/job/${jobId}/`, job, config);
        return { data: data, isUpdated: true, error: null };
    } catch (error) {
        console.error(
        "jobService:updateJob()  Error: ",
        error.response.statusText
        );
        return { data: null, isUpdated: false, error: error.response.statusText };
    }
    }

const getJobsBydId = async (jobId, token) => {
    try {
        const { data } = await axios.get(`${apiBaseUrl}/api/job/${jobId}`);
        return data;
    } catch (error) {
        console.error(
        "jobService:getJobsById()  Error: ",
        error.response.statusText
        );
        return error.response.statusText;
    }
    }

    const getJobs = async () => {
        try {
            // const config = {
            //   headers: { Authorization: `Bearer ${token}` },
            // };
            const { data } = await axios.get(`${apiBaseUrl}/api/job/all`);
            return data;
        } catch (error) {
            console.error(
            "jobService:fetchJobs() Error: ",
            error.response.statusText
            );
            return error.response.statusText;
        }
        };


export default { fetchJobs, addJob, deleteJob, updateJob, getJobsBydId, getJobs };
