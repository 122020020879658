
import * as admitCardConstants from '../constants/admitCardConstants';
import admitCardServices from '../services/admitCardServices';

export const fetchAdmitCard = async (dispatch, token) => {
    dispatch({ type: admitCardConstants.FETCH_ADMIT_CARD_REQUEST });
    const data = await admitCardServices.fetchAdmitCard(token);
    if (data) {
        return dispatch({
            type: admitCardConstants.FETCH_ADMIT_CARD_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: admitCardConstants.FETCH_ADMIT_CARD_FAILURE,
            payload: data,
        });
    }
};

    export const addAdmitCard = async (dispatch, admitCard, token) => {
        dispatch({ type: admitCardConstants.ADD_ADMIT_CARD_REQUEST });
        const { data, isAdded, error } = await admitCardServices.addAdmitCard(admitCard, token);
        if (isAdded) {
            return dispatch({
                type: admitCardConstants.ADD_ADMIT_CARD_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: admitCardConstants.ADD_ADMIT_CARD_FAILURE,
                payload: error,
            });
        }
    };
    export const deleteAdmitCard = async (dispatch, admitId, token) => {
        dispatch({ type: admitCardConstants.DELETE_ADMIT_CARD_REQUEST });
        const { isDeleted, error } = await admitCardServices.deleteAdmitCard(admitId, token);
        if (isDeleted) {
            return dispatch({
                type: admitCardConstants.DELETE_ADMIT_CARD_SUCCESS,
                payload: admitId,
            });
        } else {
            return dispatch({
                type: admitCardConstants.DELETE_ADMIT_CARD_FAILURE,
                payload: error,
            });
        }
    };
    export const updateAdmitCard = async (dispatch, admitCard,admitId, token) => {
        dispatch({ type: admitCardConstants.UPDATE_ADMIT_CARD_REQUEST });
        const { data, isUpdated, error } = await admitCardServices.updateAdmitCard(admitCard, admitId, token);
        if (isUpdated) {
            return dispatch({
                type: admitCardConstants.UPDATE_ADMIT_CARD_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: admitCardConstants.UPDATE_ADMIT_CARD_FAILURE,
                payload: error,
            });
        }
    };
    
    export const getAdmitCardById = async (dispatch, admitId, token) => {
        dispatch({ type: admitCardConstants.FETCH_ADMIT_CARD_REQUEST });
        const data = await admitCardServices.getAdmitCardById(admitId, token);
        if (data) {
            return dispatch({
                type: admitCardConstants.FETCH_ADMIT_CARD_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: admitCardConstants.FETCH_ADMIT_CARD_FAILURE,
                payload: data,
            });
        }
    }

    export const fetchAdmitById = async (dispatch, admitId) => {
        dispatch({ type: admitCardConstants.FETCH_ADMIT_CARD_REQUEST });
        const data = await admitCardServices.getAdmitCardById(admitId);
        if (data) {
            return dispatch({
                type: admitCardConstants.FETCH_ADMIT_CARD_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: admitCardConstants.FETCH_ADMIT_CARD_FAILURE,
                payload: data,
            });
        }
    }