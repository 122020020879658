import React, { useState, useEffect } from "react";
import "../categories/AdminAddCategoryPage.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as admitCardConstants from "../../../constants/admitCardConstants";
import FormContainer from "../../../components/FormContainer";
import Sidebar from "../../../components/Sidebar";
import {
  addAdmitCard,
  fetchAdmitCard
} from "../../../actions/admitCardActions";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const AdminAddAdmitCard = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [examDate, setExamDate] = useState("");
    const [admitCardLink, setAdmitCardLink] = useState("");
    const [admitCardDate, setAdmitCardDate] = useState("");
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const submitHandler = (e) => {
    e.preventDefault();
    if (title === "") {
      swal("Admit Card Not Added!", "Please Enter Title", "error");
    } else if (description === "") {
      swal("Admit Card Not Added!", "Please Enter Description", "error");
    } else {
      const admitCard = { title: title, description: description, examDate: examDate, admitCardDate: admitCardDate, admitCardLink: admitCardLink };
      addAdmitCard(dispatch, admitCard, token).then((data) => {
        if (data.type === admitCardConstants.ADD_ADMIT_CARD_SUCCESS) {
          swal("Admit Card Added!", `${title} succesfully added`, "success");
        } else {
          swal("Admit Card Not Added!", `${title} not added`, "error");
        }
        navigate("/adminAdmitcard");
      });
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);

  return (
    <div className="adminAddCategoryPage__container">
      <div className="adminAddCategoryPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminAddCategoryPage__content">
        <FormContainer>
          <h2 className="w-full text-2xl text-center">Add Admit Card</h2>
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-2" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                className="text"
                type="text"
                placeholder="Enter Category Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="text"
                style={{ textAlign: "top" }}
                as="textarea"
                rows="5"
                type="text"
                placeholder="Enter Category Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="examDate">
              <Form.Label>Exam Date</Form.Label>
              <Form.Control
                className="text"
                type="date"
                value={examDate}
                onChange={(e) => {
                  setExamDate(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="admitCardDate">
              <Form.Label>Admit Card Date</Form.Label>
              <Form.Control
                className="text"
                type="date"
                value={admitCardDate}
                onChange={(e) => {
                  setAdmitCardDate(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="admitCardLink">
              <Form.Label>Admit Card Link</Form.Label>
              <Form.Control
                className="text"
                type="text"
                placeholder="Enter Admit Card Link"
                value={admitCardLink}
                onChange={(e) => {
                  setAdmitCardLink(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Button
              className="my-2 adminAddCategoryPage__content--button text-white"
              style={{ backgroundColor: "#44b131" }}
              type="submit"
              variant=""
            >
              Add
            </Button>
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default AdminAddAdmitCard;
