import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';

function Dashboard() {
    return (
        <div className="  bg-white">
            <Carousel className="md:w-[700px] w-[300px]  mx-auto py-2 ">
                <Carousel.Item>
                    <img src="/images/cate.jpg" />

                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src="/images/cate.jpg" />
                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src="/images/cate.jpg" />
                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <div className="flex flex-col">
                <div className=" mx-auto py-20 text-4xl font-semibold">
                    <p className="text-center">One Destination for Complete Exam Preparation</p>
                </div>


                <div>
                    <p className=" capitalize text-2xl font-medium md:px-28 px-5  ">popular exams</p>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first"  >
                        <Col className="px-2 my-5 md:mx-56">
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-row shadow py-2 rounded-2xl shadow-black px-2" >
                                    <Nav.Item className="md:px-5">
                                        <Nav.Link eventKey="first" >Tab 1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} >
                                <Tab.Content className="md:px-5 py-5 px-2 shadow-lg shadow-black my-2 rounded-2xl"  >
                                    <Tab.Pane className=" bg-white" eventKey="first">First tab content</Tab.Pane>
                                    <Tab.Pane className="bg-white" eventKey="second">Second tab content</Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Col>
                    </Tab.Container>
                </div>

                <div>
                <p className=" text-2xl font-medium md:px-28 px-5 capitalize">Popular test series</p>
                    <Card style={{ width: '18rem' }} className="my-5 md:mx-56 mx-auto">
                        <Card.Img variant="center" as={'img'} src="/images/cate.jpg"  className=" rounded-full w-20 h-20"/>
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text>
                            <Button variant="primary">Go somewhere</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
