import React, { useState, useEffect } from "react";
import "../categories/AdminUpdateCategoryPage.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import * as jobConstants from "../../../constants/jobsConstants";
import FormContainer from "../../../components/FormContainer";
import Sidebar from "../../../components/Sidebar";
import { updateJob, fetchJobById } from "../../../actions/jobsActions";
import { useNavigate } from "react-router-dom";

const AdminUpdateJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const jobId = params.jobId;

const [selectedJob, setSelectedJob] = useState({});


  const [title, setTitle] = useState(
    selectedJob ? selectedJob.title : ""
  );
  const [description, setDescription] = useState(
    selectedJob ? selectedJob.description : ""
  );
  const [start, setStart] = useState(
    selectedJob ? selectedJob.start : ""
  );
    const [end, setEnd] = useState(
    selectedJob ? selectedJob.end : ""
    );
    const [examDate, setExamDate] = useState(
    selectedJob ? selectedJob.examDate : ""
    );
    const [generalFee, setGeneralFee] = useState(
    selectedJob ? selectedJob.generalFee : ""
    );
    const [scFee, setScFee] = useState(
    selectedJob ? selectedJob.scFee : ""
    );
    const [femaleFee, setFemaleFee] = useState(
    selectedJob ? selectedJob.femaleFee : ""
    );
    const [ageLimit, setAgeLimit] = useState(
    selectedJob ? selectedJob.ageLimit : ""
    );
    const [totalVacancy, setTotalVacancy] = useState(
    selectedJob ? selectedJob.totalVacancy : ""
    );
    const [generalVacancy, setGeneralVacancy] = useState(
    selectedJob ? selectedJob.generalVacancy : ""
    );
    const [scVacancy, setScVacancy] = useState(
        selectedJob ? selectedJob.scVacancy : ""
    );
    const [stVacancy, setStVacancy] = useState(
        selectedJob ? selectedJob.stVacancy : ""
    );
    const [obcVacancy, setObcVacancy] = useState(
        selectedJob ? selectedJob.obcVacancy : ""
    );
    const [ewsVacancy, setEwsVacancy] = useState(
        selectedJob ? selectedJob.ewsVacancy : ""
    );
    const [applyLink, setApplyLink] = useState(
        selectedJob ? selectedJob.applyLink : ""
    );
    const [syllabusLink, setSyllabusLink] = useState(
        selectedJob ? selectedJob.syllabusLink : ""
    );
    const [officialLink, setOfficialLink] = useState(
        selectedJob ? selectedJob.officialLink : ""
    );
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  useEffect(() => {
    fetchJobById(dispatch, jobId, token).then((data) => {
      setSelectedJob(data.payload || {});
      setTitle(data.payload ? data.payload.title : "");
        setDescription(data.payload ? data.payload.description : "");   
        setStart(data.payload ? data.payload.importantDates.start : "");
        setEnd(data.payload ? data.payload.importantDates.end : "");
        setExamDate(data.payload ? data.payload.importantDates.examDate : "");
        setGeneralFee(data.payload ? data.payload.feeDetails.general : "");
        setScFee(data.payload ? data.payload.feeDetails.sc : "");
        setFemaleFee(data.payload ? data.payload.feeDetails.female : "");
        setAgeLimit(data.payload ? data.payload.ageLimit : "");
        setTotalVacancy(data.payload ? data.payload.post.totalVacancy : "");
        setGeneralVacancy(data.payload ? data.payload.post.general : "");
        setScVacancy(data.payload ? data.payload.post.sc : "");
        setStVacancy(data.payload ? data.payload.post.st : "");
        setObcVacancy(data.payload ? data.payload.post.obc : "");
        setEwsVacancy(data.payload ? data.payload.post.ews : "");
        setApplyLink(data.payload ? data.payload.importantLinks.applyLink : "");
        setSyllabusLink(data.payload ? data.payload.importantLinks.syllabusLink : "");
        setOfficialLink(data.payload ? data.payload.importantLinks.officialLink : "");
    });
  }, [dispatch, jobId, token]);

  const submitHandler = (e) => {
    e.preventDefault();
    const jobData = {
        title: title,
        description: description,
        importantDates: {
            start: start,
            end :end,
            examDate: examDate,
          },
        feeDetails: {
            general: generalFee,
            sc: scFee,
            female: femaleFee,
        },
        ageLimit: ageLimit,
        post: {
            totalVacancy: totalVacancy,
            general: generalVacancy,
            sc: scVacancy,
            st: stVacancy,
            obc: obcVacancy,
            ews: ewsVacancy,
        },
        importantLinks: {
            applyLink: applyLink,
            syllabusLink: syllabusLink,
            officialLink: officialLink,
        },
    }
    updateJob(dispatch, jobId, jobData, token).then((data) => {
      if (data.type === jobConstants.UPDATE_JOBS_SUCCESS) {
        swal("Job Updated!", `${title} succesfully updated`, "success");
      } else {
        swal("Job Not Updated!", `${title} not updated`, "error");
      }
    });
    navigate("/adminJobs");
  };
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);
  return (
    <div className="adminUpdateCategoryPage__container">
      <div className="adminUpdateCategoryPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminUpdateCategoryPage__content">
        <FormContainer>
          <h2>Update Job</h2>
          <Form onSubmit={submitHandler}>
                        <Form.Group controlId="title">
                            {/* <Form.Label>Title</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Job Title"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId="description">
                            {/* <Form.Label>Description</Form.Label> */}
                            <Form.Control
                                style={{ textAlign: "top" }}
                                as="textarea"
                                rows="5"
                                type="text"
                                placeholder="Enter Category Description"
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <div className="flex gap-2 my-2">
                            
                        <Form.Group controlId="start">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Start Date"
                                value={start}
                                onChange={(e) => {
                                    setStart(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="end">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Last Date"
                                value={end}
                                onChange={(e) => {
                                    setEnd(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        
                        </div>
                        <Form.Group controlId="examDate">
                            <Form.Label>Exam Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Exam Date"
                                value={examDate}
                                onChange={(e) => {
                                    setExamDate(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <p>Enter Fee Details</p>
                        <div className="flex gap-2 my-2">
                        <Form.Group controlId="generalFee">
                            {/* <Form.Label>General Fee</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="General Fee"
                                value={generalFee}
                                onChange={(e) => {
                                    setGeneralFee(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="scFee">
                            {/* <Form.Label>SC Fee</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="SC Fee"
                                value={scFee}
                                onChange={(e) => {
                                    setScFee(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="femaleFee">
                            {/* <Form.Label>Female Fee</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Female Fee"
                                value={femaleFee}
                                onChange={(e) => {
                                    setFemaleFee(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        </div>
                        <Form.Group controlId="ageLimit">
                            {/* <Form.Label>Age Limit</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Age Limit"
                                value={ageLimit}
                                onChange={(e) => {
                                    setAgeLimit(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <p>Vacancy Details</p>
                        <Form.Group controlId="totalVacancy">
                            {/* <Form.Label>Total Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Total Vacancy"
                                value={totalVacancy}
                                onChange={(e) => {
                                    setTotalVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="generalVacancy">
                            {/* <Form.Label>General Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter General Vacancy"
                                value={generalVacancy}
                                onChange={(e) => {
                                    setGeneralVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="scVacancy">
                            {/* <Form.Label>SC Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter SC Vacancy"
                                value={scVacancy}
                                onChange={(e) => {
                                    setScVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="stVacancy">
                            {/* <Form.Label>ST Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter ST Vacancy"
                                value={stVacancy}
                                onChange={(e) => {
                                    setStVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="obcVacancy">
                            {/* <Form.Label>OBC Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter OBC Vacancy"
                                value={obcVacancy}
                                onChange={(e) => {
                                    setObcVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="ewsVacancy">
                            {/* <Form.Label>EWS Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter EWS Vacancy"
                                value={ewsVacancy}
                                onChange={(e) => {
                                    setEwsVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <p>Important Links</p>
                        <Form.Group controlId="applyLink">
                            {/* <Form.Label>Apply Link</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Apply Link"
                                value={applyLink}
                                onChange={(e) => {
                                    setApplyLink(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="syllabusLink">
                            {/* <Form.Label>Syllabus Link</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Syllabus Link"
                                value={syllabusLink}
                                onChange={(e) => {
                                    setSyllabusLink(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="officialLink">
                            {/* <Form.Label>Official Link</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Official Link"
                                value={officialLink}
                                onChange={(e) => {
                                    setOfficialLink(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        
                        



                        <Button
                            className="my-2 adminAddCategoryPage__content--button text-white"
                            style={{ backgroundColor: "#44b131" }}
                            type="submit"
                            variant=""
                        >
                            Update
                        </Button>
                    
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default AdminUpdateJobs;
