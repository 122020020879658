import React, { useEffect, useState } from "react";
import "./AdminQuizzesPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup } from "react-bootstrap";
import Message from "../../../components/Message";
import Sidebar from "../../../components/Sidebar";
import Loader from "../../../components/Loader";
import { deleteQuiz, fetchQuizzes } from "../../../actions/quizzesActions";
import * as quizzesConstants from "../../../constants/quizzesConstants";
import swal from "sweetalert";

const AdminQuizzesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const catId = urlParams.get("catId");
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const quizzesReducer = useSelector((state) => state.quizzesReducer);
  const [quizzes, setQuizzes] = useState([]);

  const addNewQuizHandler = () => {
    navigate("/adminAddQuiz");
  };
 
  const deleteQuizHandler = (quiz) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this quiz!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteQuiz(dispatch, quiz._id).then((data) => {
          if (data.type === quizzesConstants.DELETE_QUIZ_SUCCESS) {
            swal(
              "Quiz Deleted!",
              `${quiz.title} succesfully deleted`,
              "success"
            );
          } else {
            swal("Quiz Not Deleted!", `${quiz.title} not deleted`, "error");
          }
        });
      } else {
        swal(`${quiz.title} is safe`);
      }
    });
  };
  const updateQuizHandler = (quizTitle, quiz) => {
    navigate(`/adminUpdateQuiz/${quiz._id}`);
  };

  const questionsHandler = (quizTitle, _id) => {
    navigate(`/adminQuestions/?questionid=${_id}&categoryTitle=${quizTitle}`);
  };

  useEffect(() => {
      fetchQuizzes(dispatch).then((data) => {
        setQuizzes(data.payload);
      });
  }, []);
  // useEffect(() => {
  //   setQuizzes([
  //     {
  //       _id: 1,
  //       title: "Example Quiz 1",
  //       description: "This is an example quiz.",
  //       numOfQuestions: 10,
  //       category: {
  //         catId: 1,
  //         title: "Example Category",
  //         description: "This is an example category.",
  //       },
  //     },
  //     {
  //       quizId: 2,
  //       title: "Example Quiz 2",
  //       description: "Another example quiz.",
  //       numOfQuestions: 15,
  //       category: {
  //         catId: 2,
  //         title: "Another Category",
  //         description: "This is another example category.",
  //       },
  //     },
  //     // Add more example quizzes if needed
  //   ]);
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);

  return (
    <div className="adminQuizzesPage__container flex md:flex-row">
      <div className="adminQuizzesPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminQuizzesPage__content">
        <h2 className="p-3">Test Series</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3 p-1 flex flex-col md:flex-row margin-auto">
        {quizzes ? (
          quizzes.length === 0 ? (
            <Message>No quizzes are present. Try adding some quizzes.</Message>
          ) : (
            quizzes.map((quiz, index) => {
              return (
                <ListGroup
                  className="adminQuizzesPage__content--quizzesList"
                  key={index}
                >
                  <ListGroup.Item className="glass" action>
                    <div className="ms-2 me-auto ">
                      <div className="fw-bold">{quiz.title}</div>
                      <p>{quiz.category}</p>
                      {<p className="">{quiz.description}</p>}
                      <div className="adminQuizzesPage__content--ButtonsList">
                        <div
                          onClick={() => questionsHandler(quiz.title, quiz._id)}
                          className="w-[100px] h-[40px] text-center border-2 border-gray-400 bg-green-500 text-white mb-2 px-2 py-1 rounded-lg"
                        >{`Questions`}</div>
                        <div
                          className="border-2 border-gray-600 mb-2 px-2 py-1 rounded-lg mx-2"
                        >{`Marks : ${quiz.questions.length*1}`}</div>
                        <div
                          className="border-2 border-gray-600 mb-2 px-2 py-1 rounded-lg"

                        >{`${quiz.questions.length} Questions`}</div>
                        <div
                          className="w-[100px] h-[40px] text-center mb-2 border-2 border-gray-400 bg-green-500 text-white px-2 py-1 rounded-lg mx-2"
                          onClick={() => updateQuizHandler(quiz.title, quiz)}

                        >{`Update`}</div>
                        <div
                          className="w-[100px] h-[40px] text-center mb-2 border-2 border-gray-400 bg-[#ff0b0bdb] text-white px-2 py-1 rounded-lg"
                          onClick={() => deleteQuizHandler(quiz)}
                        >{`Delete`}</div>
                      </div>
                    </div>
                    {/* <Badge bg="primary" pill></Badge> */}
                  </ListGroup.Item>
                </ListGroup>
              );
            })
          )
        ) : (
          <Loader />
        )}
        </div>
        <Button
          variant=""
          className="btn btn-success inline p-3"
          onClick={addNewQuizHandler}
        >
          Add Quiz
        </Button>
      </div>
    </div>
  );
};

export default AdminQuizzesPage;
