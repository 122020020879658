import React, { useState, useEffect } from "react";
// import SidebarUser from "../../components/SidebarUser";
import "./UserQuizResultPage.css";
import Message from "../../components/Message";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { fetchQuizzes } from "../../actions/quizzesActions";
// import JobdetailComponent from "../../components/JobdetailComponent";
// import TableComponent from "../../components/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup } from "react-bootstrap";
import { fetchQuestionsByQuiz } from "../../actions/questionsActions";
import "../../components/Question.css";
import Loader from "../../components/Loader";


const UserQuizResultPage = () => {
  const [result, setResult] = useState();
  const [quizzes, setQuizzes] = useState(null);
  const dispatch = useDispatch();
  const [quiz, setQuiz] = useState([]);
  const token = JSON.parse(localStorage.getItem("jwtToken"));
  const [quizId, setQuizId] = useState()
  const questionsReducer = useSelector((state) => state.questionsReducer);
  const [questions, setQuestions] = useState(questionsReducer.questions);
  const [questionSize, setQuestionSize] = useState(0);

  useEffect(() => {
    fetchQuizzes(dispatch, token).then((data) => {
      const temp = data.payload;
      setQuizzes(temp);
      setQuiz(temp.filter((q) => q.quizId == quizId)[0]);
    });
  }, []);

  useEffect(() => {
    const storedResult = sessionStorage.getItem("quizResult");
    if (storedResult) {
      const res = JSON.parse(storedResult);
      setResult(res);
      setQuizId(res.quizId);
    }
  }, []);

  useEffect(() => {
    if (quizId) {
      fetchQuestionsByQuiz(dispatch, quizId, token).then((data) => {
        setQuestions(data.payload);
      });
    }
  }, [quizId, dispatch, token]);

  useEffect(() => {
    if (quiz && quiz.questions) {
      setQuestionSize(quiz?.questions?.length);
    }
  }, [quiz]);


  return (
    <div className="userQuizResultPage__container">
      <div className="userQuizResultPage__sidebar">{/* <SidebarUser /> */}</div>

      <div className="userQuizResultPage__content">
        {result && quiz ? (
          <>
            <Table bordered className="userQuizResultPage__content--table">
              <thead>
                <tr>
                  {/* <th>Quiz Id</th> */}
                  <th>Quiz Name</th>
                  <th>Category Name</th>
                  <th>Obtained Marks</th>
                  <th>Total Marks</th>
                  <th>Attempted Qustions</th>
                  <th>Total Qustions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td>{quizId}</td> */}
                  <td>{quiz.title}</td>
                  <td>{quiz.category}</td>
                  <td>{result?.obtainedMarks}</td>
                  <td>{questions?.length * 2}</td>
                  <td>{result.numberOfQuestionAnswered}</td>
                  <td>{questions?.length}</td>
                </tr>
              </tbody>
            </Table>
            {questions ? (
              questions.map((question, index) => {
                return <>
                  <div className="question__container">
                    <div className="question__content">
                      {index+1 + ". " + question.question}
                    </div>
                    <div className="question__options">
                      <InputGroup>
                        <div className="question__options--2">
                          <div className="question__options--optionDiv">
                            <InputGroup.Radio
                              value={question.options[0]}
                              name={index}
                              aria-label="option 1"
                              disabled
                            />
                            <span className="question__options--optionText">
                              {question.options[0]}
                            </span>
                          </div>
                          <div className="question__options--optionDiv">
                            <InputGroup.Radio
                              value={question.options[1]}
                              name={index}
                              aria-label="option 2"
                              disabled
                            />
                            <span className="question__options--optionText">
                              {question.options[1]}
                            </span>
                          </div>
                        </div>

                        <div className="question__options--2">
                          <div className="question__options--optionDiv">
                            <InputGroup.Radio
                              value={question.options[2]}
                              name={index}
                              aria-label="option 3"
                              disabled
                            />
                            <span className="question__options--optionText">
                              {question.options[2]}
                            </span>
                          </div>
                          <div className="question__options--optionDiv">
                            <InputGroup.Radio
                              value={question.options[3]}
                              name={index}
                              aria-label="option 4"
                              disabled
                            />
                            <span className="question__options--optionText">
                              {question.options[3]}
                            </span>
                          </div>
                        </div>
                      </InputGroup>
                    </div>
                    <div>
                      <h4 style={{color:"green", marginLeft:"1rem", fontSize:"1rem"}}>Correct Answer : {question.correctAnswer}</h4>
                    </div>
                  </div>
                </>
              })
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <Message>
            No results to display. Attempt any <Link to="/quizzes">Quiz.</Link>
          </Message>
        )}
      </div>
    </div>
  );
};

export default UserQuizResultPage;
