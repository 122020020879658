export const FETCH_QUIZZES_REQUEST = "FETCH_QUIZZES_REQUEST";
export const FETCH_QUIZZES_SUCCESS = "FETCH_QUIZZES_SUCCESS";
export const FETCH_QUIZZES_FAILURE = "FETCH_QUIZZES_FAILURE";

export const ADD_QUIZ_REQUEST = "ADD_QUIZ_REQUEST";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAILURE = "ADD_QUIZ_FAILURE";

export const DELETE_QUIZ_REQUEST = "DELETE_QUIZ_REQUEST";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILURE = "DELETE_QUIZ_FAILURE";

export const UPDATE_QUIZ_REQUEST = "UPDATE_QUIZ_REQUEST";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAILURE = "UPDATE_QUIZ_FAILURE";