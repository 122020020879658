import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const fetchQuestionsByQuiz = async (quizId, token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.get(
      `${apiBaseUrl}/api/question/quiz/${quizId}`,
      
    );
    return data;
  } catch (error) {
    console.error(
      "questionsServices:fetchQuestionsByQuiz() Error: ",
      error.response.statusText
    );
    return error.response.statusText;
  }
};

const addQuestion = async (question,token,quizId) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.post(`${apiBaseUrl}/api/question/${quizId}`, question);
    return { data: data, isAdded: true, error: null };
  } catch (error) {
    console.error(
      "questionsServices:addQuestion()  Error: ",
      error.response.statusText
    );
    return { data: null, isAdded: false, error: error.response.statusText };
  }
};

const deleteQuestion = async (quesId, token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.delete(`${apiBaseUrl}/api/question/${quesId}`);
    return {
      isDeleted: true,
      error: null,
    };
  } catch (error) {
    console.error(
      "questionsServices:deleteQuestion() Error: ",
      error.response.statusText
    );
    return {
      isDeleted: false,
      error: error.response.statusText,
    };
  }
};

const updateQuestion = async (question,quesId, token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.put(
      `${apiBaseUrl}/api/question/${quesId}`,
      question
    );
    return {
      data: data,
      isUpdated: true,
      error: null,
    };
  } catch (error) {
    console.error(
      "questionsServices:updateQuestion() Error: ",
      error.response.statusText
    );
    return {
      data: null,
      isUpdated: false,
      error: error.response.statusText,
    };
  }
};

const getQuestionById = async (quesId, token) => {
  try {
      const { data } = await axios.get(`${apiBaseUrl}/api/question/${quesId}`);
      return data;
  } catch (error) {
      console.error(
      "jobService:getJobsById()  Error: ",
      error.response.statusText
      );
      return error.response.statusText;
  }
  }

const questionsServices = {
  fetchQuestionsByQuiz,
  addQuestion,
  deleteQuestion,
  updateQuestion,
  getQuestionById
};
export default questionsServices;