import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Dashboard from "../pages/users/Dashboard";
import { MdDashboard, MdOutlineLogout } from "react-icons/md";

const Footer = () => {
  const loginReducer = useSelector((state) => state.loginReducer);
  const [isLoggedIn, setIsLoggedIn] = useState(loginReducer?.loggedIn);
  const navigate = useNavigate();
  const logoutHandler = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    setIsLoggedIn(loginReducer?.loggedIn);
  }, [loginReducer?.loggedIn]);
  return (
    <footer style={{ background: "#002b50", color: "#fff", padding: "20px 0" }}>
      <Container>
        <Nav className="justify-content-center">
          <Nav.Item>
            <Nav.Link href="/">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="about-us">About Us</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="contact-us">Contact</Nav.Link>
          </Nav.Item>
          {/* <Nav className="justify-content-end flex-grow-1 pe-3"> */}
          {isLoggedIn ? (
            <LinkContainer to="/adminProfile">
              <Button
                variant="success"
                className="inline-flex items-center mr-4"
              >
                <MdDashboard className="mr-2 inline" />
                Dashboard
              </Button>
            </LinkContainer>
          ) : (
            <LinkContainer to="/login">
              <Button variant="danger" className="inline-flex items-center">
                <MdOutlineLogout className="mr-2 inline" />
                Login
              </Button>
            </LinkContainer>
          )}

          {isLoggedIn ? (
            <LinkContainer to="/">
              <Button
                variant="danger"
                onClick={logoutHandler}
                className="inline-flex items-center"
              >
                <MdOutlineLogout className="mr-2 inline" />
                Logout
              </Button>
            </LinkContainer>
          ) : (
            ""
          )}
        </Nav>
        <p className="text-center mt-4">
          &copy; 2024{" "}
          <a
            href="Sarkariexasmhub.com"
            className="text-gray-400 hover:text-gray-600 font-bold"
          >
            Sarkari Exams Hub
          </a>
          . All rights reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
