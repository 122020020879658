export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const ADD_JOBS_REQUEST = "ADD_JOBS_REQUEST";
export const ADD_JOBS_SUCCESS = "ADD_JOBS_SUCCESS";
export const ADD_JOBS_FAILURE = "ADD_JOBS_FAILURE";

export const DELETE_JOBS_REQUEST = "DELETE_JOBS_REQUEST";
export const DELETE_JOBS_SUCCESS = "DELETE_JOBS_SUCCESS";
export const DELETE_JOBS_FAILURE = "DELETE_JOBS_FAILURE";

export const UPDATE_JOBS_REQUEST = "UPDATE_JOBS_REQUEST";
export const UPDATE_JOBS_SUCCESS = "UPDATE_JOBS_SUCCESS";
export const UPDATE_JOBS_FAILURE = "UPDATE_JOBS_FAILURE";