import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const TableComponent = ({ data, heading, columns, name, link }) => {
  const [visibleRows, setVisibleRows] = useState(5);

  const handleSeeMoreClick = () => {
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 5);
  };

  const onApplyClick = (dataId) => {
    // Handle apply button click for the specific job ID
  };

  return (
    <div className="w-full">
      <div className="w-3/4 m-auto">
        <h1 className="text-center py-2 font-semibold">{heading}</h1>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key} className="text-center">
                  {column.label}
                </th>
              ))}
              <th className="text-center" style={{ width: "80px" }}>
                Apply Online
              </th>
            </tr>
          </thead>
          <tbody>
            {data.slice(0, visibleRows).map((data) => (
              <tr key={data._id}>
                {columns.map((column) => (
                  <td key={column.key} className="text-center">
                    {column.format
                      ? column.format(data[column.key])
                      : data[column.key]}
                  </td>
                ))}
                <td className="text-center">
                  <Link to={`${link}/${data._id}`}>
                    <Button
                      variant="primary"
                      onClick={() => onApplyClick(data._id)}
                    >
                      {name}
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {visibleRows < data.length && (
          <div className="text-center">
            <Button variant="secondary" onClick={handleSeeMoreClick}>
              See More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableComponent;
