import React from "react";
import TableComponent from "../../components/TableComponent";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchResults } from "../../actions/resultActions";
const Result = () => {
  const [resultData, setResultData] = useState([]);
  const dispatch = useDispatch();

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  };

  // const formattedData = yourData.map((row) => {
  //   return columns.map((column) => {
  //     const value = row[column.key];
  //     return column.format ? column.format(value) : value;
  //   });
  // });

  const columns = [
    // { key: "_id", label: "ID" },
    { key: "title", label: "Result Title" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Post Date",  format: formatDate },
  ];

  useEffect(() => {
    fetchResults(dispatch).then((data) => {
      setResultData(data.payload);
    });
  }, []);

  return (
    <TableComponent
      data={resultData}
      heading="Results"
      columns={columns}
      name="See Result"
      link="/job-results"
    />
  );
};

export default Result;
