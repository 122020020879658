import React, { useEffect, useState } from "react";
import "./categories/AdminCategoriesPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup } from "react-bootstrap";
import * as categoriesConstants from "../../constants/contactConstants";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Sidebar from "../../components/Sidebar";
import {
    getContacts,
} from "../../actions/contactActions";
import swal from "sweetalert";

const AdminContactPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("jwtToken"));

//   const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const [contact, setContact] = useState([]);

  const categoryClickHandler = (catId) => {
    navigate(`/adminQuizzes/?catId=${catId}`);
  };


//   const deleteCategoryHandler = (event, category) => {
//     event.stopPropagation();
//     swal({
//       title: "Are you sure?",
//       text: "Once deleted, you will not be able to recover this category!",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         deleteCategory(dispatch, category._id, token).then((data) => {
//           if (data.type === categoriesConstants.DELETE_CATEGORY_SUCCESS) {
//             swal(
//               "Category Deleted!",
//               `${category.title} succesfully deleted`,
//               "success"
//             );
//           } else {
//             swal(
//               "Category Not Deleted!",
//               `${category.title} not deleted`,
//               "error"
//             );
//           }
//         });
//       } else {
//         swal(`${category.title} is safe`);
//       }
//     });
//   };

  

  useEffect(() => {
    if (contact.length === 0) {
      getContacts(dispatch).then((data) => {
        setContact(data.payload);
      });
    }
  }, []);
  // const category = [
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  // ];
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);

  return (
    <div className="adminCategoriesPage__container flex md:flex-row">
      <div className="adminCategoriesPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminCategoriesPage__content ">
        <h2 className="p-3">Contact Page</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-3 p-1 flex flex-col md:flex-row">
          {contact ? (
            contact.length === 0 ? (
              <Message>
                No categories are present. Try adding some categories.
              </Message>
            ) : (
              contact.map((con, index) => {
                return (
                  <div
                    className="adminCategoriesPage__content--categoryCard mb-3 md:mb-0"
                    key={index}
                  >
                    <ListGroup.Item
                      className="d-flex card-body"
                    //   onClick={() => categoryClickHandler(con.con._id)}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{con.name}</div>
                        <div style={{fontSize:"15px", fontWeight:"bold"}}>
                        {con.email}
                        </div>
                        <div style={{fontSize:"15px", color:"red", fontWeight:"500", marginBottom:"5px"}}>
                            {con.mobileNumber}
                        </div>
                        <div>
                            <p>Message: {con.message}</p>
                            
                        </div>
                      </div>

                      <div className="flex items-center">
                        {/* <button
                          onClick={(event) => updateCategoryHandler(event, cat)}
                          className="action-button btn btn-success"
                        >
                          {`Update`}
                        </button> */}

                        {/* <button
                          onClick={(event) => deleteCategoryHandler(event, cat)}
                          className="action-button delete btn btn-danger"
                        >
                          {`Delete`}
                        </button> */}
                      </div>
                    </ListGroup.Item>
                  </div>
                );
              })
            )
          ) : (
            <Loader />
          )}
        </div>
        {/* <Button
          variant=""
          className="btn btn-success inline p-3 "
          onClick={addNewCategoryHandler}
        >
          Add Exam Category
        </Button> */}
      </div>
    </div>
    
  );
};

export default AdminContactPage;
