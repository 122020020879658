import React, { useState, useEffect } from "react";
import "../categories/AdminUpdateCategoryPage.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import * as admitCardConstants from "../../../constants/admitCardConstants";
import FormContainer from "../../../components/FormContainer";
import Sidebar from "../../../components/Sidebar";
import { getAdmitCardById, updateAdmitCard } from "../../../actions/admitCardActions";
import { useNavigate } from "react-router-dom";

const AdminUpdateAdmitCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const admitId = params.admitId;

  const [selectedAdmitCard, setSelectedAdmitCard] = useState({});

  

  const [title, setTitle] = useState(
    selectedAdmitCard ? selectedAdmitCard.title : ""
  );
  const [description, setDescription] = useState(
    selectedAdmitCard ? selectedAdmitCard.description : ""
  );
  const [examDate, setExamDate] = useState(
    selectedAdmitCard ? selectedAdmitCard.examDate : ""
  );
  const [admitCardLink, setAdmitCardLink] = useState(
    selectedAdmitCard ? selectedAdmitCard.admitCardLink : ""
  );
  const [admitCardDate, setAdmitCardDate] = useState(
    selectedAdmitCard ? selectedAdmitCard.admitCardDate : ""
  );

  const token = JSON.parse(localStorage.getItem("jwtToken"));
  
  const submitHandler = (e) => {
    e.preventDefault();
    const admitCard = {title: title, description: description, examDate: examDate, admitCardDate: admitCardDate, admitCardLink: admitCardLink};
    updateAdmitCard(dispatch, admitCard,admitId, token).then((data) => {
      if (data.type === admitCardConstants.UPDATE_ADMIT_CARD_SUCCESS) {
        swal("Admit Card Updated!", `${title} succesfully updated`, "success");
      } else {
        swal("Admit Card Not Updated!", `${title} not updated`, "error");
      }
    });
    navigate("/adminAdmitcard");
  };

  useEffect(() => {
    getAdmitCardById(dispatch, admitId, token).then((data) => {
      setSelectedAdmitCard(data.payload || {});
      setTitle(data.payload ? data.payload.title : "");
        setDescription(data.payload ? data.payload.description : "");   
        setExamDate(data.payload ? data.payload.examDate : "");
        setAdmitCardLink(data.payload ? data.payload.admitCardLink : "");
        setAdmitCardDate(data.payload ? data.payload.admitCardDate : "");
      });
    }, [dispatch, admitId, token]);
    useEffect(() => {
      if (!localStorage.getItem("jwtToken")) navigate("/");
    }, []);
  

  return (
    <div className="adminUpdateCategoryPage__container">
      <div className="adminUpdateCategoryPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminUpdateCategoryPage__content">
        <FormContainer>
          <h2>Update Admit Card</h2>
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-2" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                style={{ textAlign: "top" }}
                as="textarea"
                rows="5"
                type="text"
                placeholder="Enter Category Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="examDate">
              <Form.Label>Exam Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Exam Date"
                value={examDate}
                onChange={(e) => {
                  setExamDate(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="admitCardDate">
              <Form.Label>Admit Card Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Admit Card Date"
                value={admitCardDate}
                onChange={(e) => {
                  setAdmitCardDate(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
                
            <Form.Group className="my-2" controlId="admitCardLink">
              <Form.Label>Admit Card Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Admit Card Link"
                value={admitCardLink}
                onChange={(e) => {
                  setAdmitCardLink(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>



            <Button
              className="my-2 adminUpdateCategoryPage__content--button"
              type="submit"
              variant=""
            >
              Update
            </Button>
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default AdminUpdateAdmitCard;
