import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-w-screen-xl mx-auto mb-2">
      <Slider {...settings}>
        {/* Slide 1 */}
        <div className="p-4">
          <div className="bg-gradient-to-b from-orange-200 via-white shadow-xl to-white h-60 flex items-center justify-center rounded-xl">
            {/* Card Content */}
            <div className="py-2 text-center">
              <img
                src="/images/shagun.jpg"
                alt="Card Image"
                className="w-16 h-16 object-cover rounded-full mx-auto mb-2"
              />
              <h3 className="text-base font-semibold mb-1">Shagun</h3>
              {/* <p className="text-sm text-gray-600">Subtitle</p> */}
              <p className="text-gray-600 text-justify text-sm p-2">
                Navigating the test series section is a breeze! Comprehensive
                questions, detailed explanations, and a user-friendly design
                enhance the learning experience.
              </p>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="bg-gradient-to-b from-orange-200 via-white shadow-xl to-white h-60 flex items-center justify-center rounded-xl">
            {/* Card Content */}
            <div className="text-center">
              <img
                src="/images/prakash.jpg"
                alt="Card Image"
                className="w-16 h-16 object-cover rounded-full mx-auto mb-2"
              />
              <h3 className="text-base font-semibold mb-1">Prakash</h3>
              {/* <p className="text-sm text-gray-600">Subtitle</p> */}
              <p className="text-gray-600 text-justify text-sm p-2">
                Top-notch test series! A plethora of well-crafted questions,
                insightful solutions, and an intuitive layout. A valuable
                resource for exam preparation.
              </p>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="bg-gradient-to-b from-orange-200 via-white shadow-xl to-white h-60 flex items-center justify-center rounded-xl">
            {/* Card Content */}
            <div className="text-center">
              <img
                src="/images/manish.jpg"
                alt="Card Image"
                className="w-16 h-16 rounded-full object-cover mx-auto mb-2"
              />
              <h3 className="text-base font-semibold mb-1">Manish</h3>
              {/* <p className="text-sm text-gray-600">Subtitle</p> */}
              <p className="text-gray-600 text-justify text-sm p-2">
                The test series section is a gem! Varied topics, challenging
                questions, and detailed feedback create a conducive environment
                for skill enhancement.
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
