import React, { useState, useEffect } from "react";
import "../categories/AdminAddCategoryPage.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as resultConstants from "../../../constants/resultConstants";
import FormContainer from "../../../components/FormContainer";
import Sidebar from "../../../components/Sidebar";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { addResult } from "../../../actions/resultActions";

const AdminAddResult = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [resultDate, setResultDate] = useState("");
  const [resultLink, setResultLink] = useState("");
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const submitHandler = (e) => {
    e.preventDefault();
    if (title === "") {
      swal("Result Not Added!", "Please Enter Title", "error");
    } else if (description === "") {
      swal("Result Not Added!", "Please Enter Description", "error");
    } else {
      const result = { title: title, description: description , resultDate: resultDate, resultLink: resultLink};
      
      addResult(dispatch, result, token).then((data) => {

        if (data.type === resultConstants.ADD_RESULT_SUCCESS) {
            swal("Result Added!", `${title} succesfully added`, "success");
        } else {
            swal("Result Not Added!", `${title} not added`, "error");
        }
        navigate("/adminResult");
    });
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);

  return (
    <div className="adminAddCategoryPage__container">
      <div className="adminAddCategoryPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminAddCategoryPage__content">
        <FormContainer>
          <h2 className="w-full text-2xl text-center">Add Result</h2>
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-2" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                className="text"
                type="text"
                placeholder="Enter Category Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="text"
                style={{ textAlign: "top" }}
                as="textarea"
                rows="5"
                type="text"
                placeholder="Enter Category Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="resultDate">
                <Form.Label>Result Date</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Enter Result Date"
                    value={resultDate}
                    onChange={(e) => {
                    setResultDate(e.target.value);
                    }}
                ></Form.Control>
            </Form.Group>

            <Form.Group className="my-2" controlId="resultLink">
                <Form.Label>Result Link</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Result Link"
                    value={resultLink}
                    onChange={(e) => {
                    setResultLink(e.target.value);
                    }}
                ></Form.Control>
            </Form.Group>

            <Button
              className="my-2 adminAddCategoryPage__content--button text-white"
              style={{ backgroundColor: "#44b131" }}
              type="submit"
              variant=""
            >
              Add
            </Button>
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default AdminAddResult;
