import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import AdminAddCategoryPage from "./pages/admin/categories/AdminAddCategoryPage";
import AdminCategoriesPage from "./pages/admin/categories/AdminCategoriesPage";
import AdminUpdateCategoryPage from "./pages/admin/categories/AdminUpdateCategoryPage";
import AdminProfilePage from "./pages/admin/AdminProfilePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AdminQuizzesPage from "./pages/admin/quizzes/AdminQuizzesPage";
import AdminAddQuiz from "./pages/admin/quizzes/AdminAddQuiz";
import Jobs from "./pages/users/Jobs";
import AdminUpdateQuiz from "./pages/admin/quizzes/AdminUpdateQuiz";
import AdminQuestionsPage from "./pages/admin/questions/AdminQuestionsPage";
import AdminAddQuestionsPage from "./pages/admin/questions/AdminAddQuestionsPage";
import AdminUpdateQuestionPage from "./pages/admin/questions/AdminUpdateQuestionPage";
import UserProfilePage from "./pages/users/UserProfilePage";
import UserQuizzesPage from "./pages/users/UserQuizzesPage";
import UserQuizManualPage from "./pages/users/UserQuizManualPage";
import UserQuestionsPage from "./pages/users/UserQuestionsPage";
import UserQuizResultPage from "./pages/users/UserQuizResultPage";
import AdminQuizResultPage from "./pages/admin/AdminQuizResultPage";
import Dashboard from "./pages/users/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer";
import JobDetail from "./pages/users/JobDetail";
import AdmitCard from "./pages/users/AdmitCard";
import Result from "./pages/users/Result";
import Contact from "./pages/users/Contact";
import AboutUs from "./pages/users/AboutUs";
import AdminJobsPage from "./pages/admin/jobs/AdminJobsPage";
import AdminAddJobs from "./pages/admin/jobs/AdminAddJobs";
import AdminUpdateJobs from "./pages/admin/jobs/AdminUpdateJobs";
import AdminResultPage from "./pages/admin/results/AdminResultPage";
import AdminAddResult from "./pages/admin/results/AdminAddResult";
import AdminUpdateResult from "./pages/admin/results/AdminUpdateResult";
import AdminAdmitCard from "./pages/admin/admitCard/AdminAdmitCard";
import AdminAddAdmitCard from "./pages/admin/admitCard/AdminAddAdmitCard";
import AdminUpdateAdmitCard from "./pages/admin/admitCard/AdminUpdateAdmitCard";
import ResultDetailComponent from "./components/ResultDetailComponent";
import ResultDetail from "./pages/users/ResultDetail";
import AdmitCardDetail from "./pages/users/AdmitCardDetail";
import AdminContactPage from "./pages/admin/AdminContactPage";
import HomePage from "./pages/users/HomePage";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        {/* <Route path="/" element={<Comingsoon />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
        <Route path="/adminProfile" element={<AdminProfilePage />} />
        <Route path="/adminCategories" element={<AdminCategoriesPage />} />
        <Route path="/adminAddCategory" element={<AdminAddCategoryPage />} />
        <Route
          path="/adminUpdateCategory/:catId"
          element={<AdminUpdateCategoryPage />}
        />
        <Route path="/adminQuizzes" element={<AdminQuizzesPage />} />
        <Route path="/adminAddQuiz" element={<AdminAddQuiz />} />
        <Route path="/adminUpdateQuiz/:quizId" element={<AdminUpdateQuiz />} />
        <Route path="/adminQuestions" element={<AdminQuestionsPage />} />
        <Route path="/adminAddQuestion" element={<AdminAddQuestionsPage />} />
        <Route path="/adminJobs" element={<AdminJobsPage />} />
        <Route path="/adminAddJobs" element={<AdminAddJobs />} />
        <Route path="/adminUpdateJobs/:jobId" element={<AdminUpdateJobs />} />
        <Route path="/adminResult" element={<AdminResultPage />} />
        <Route path="/adminAddResult" element={<AdminAddResult />} />
        <Route
          path="/adminUpdateResult/:resId"
          element={<AdminUpdateResult />}
        />
        <Route path="/adminAdmitcard" element={<AdminAdmitCard />} />
        <Route path="/adminAddAdmitcard" element={<AdminAddAdmitCard />} />
        <Route
          path="/adminUpdateAdmitcard/:admitId"
          element={<AdminUpdateAdmitCard />}
        />
        <Route path="/adminContact" element={<AdminContactPage />} />
        {/* <Route path="/adminallResult" element={<AdminQuizResultPage />} /> */}
        <Route
          path="/adminUpdateQuestion/:quesId"
          element={<AdminUpdateQuestionPage />}
        />

        {/* user routes */}
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/quizzes" element={<UserQuizzesPage />} />
        <Route path="/quiz/*" element={<UserQuizzesPage />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/jobs-detail/:jobId" element={<JobDetail />} />
        <Route path="/quizManual" element={<UserQuizManualPage />} />
        <Route path="/questions" element={<UserQuestionsPage />} />
        <Route path="/admit-card" element={<AdmitCard />} />
        <Route path="/admit-card/:admitId" element={<AdmitCardDetail />} />
        <Route path="/job-results" element={<Result />} />
        <Route path="/job-results/:resultId" element={<ResultDetail />} />
        <Route path="/quiz-results/:quizId" element={<UserQuizResultPage />} />
        {/* <Route path="/quiz-results" element={<UserQuizResultPage />} /> */}
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
