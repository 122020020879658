import React, { useState } from "react";
import { FaBars, FaUserAlt, FaRegChartBar } from "react-icons/fa";
import { TbLayoutGrid, TbLayoutGridAdd, TbReport } from "react-icons/tb";
import { MdQuiz, MdQueue, MdOutlineLogout } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdSystemUpdateAlt } from "react-icons/md";
import { TbBrandAirtable } from "react-icons/tb";
import { FaRegCopy } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import "./Sidebar.css";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/adminProfile",
      name: "Profile",
      icon: <FaUserAlt />,
    },
    {
      path: "/adminCategories",
      name: "Exam Categories",
      icon: <TbLayoutGrid />,
    },
    {
      path: "/adminAddCategory",
      name: "Add Category",
      icon: <TbLayoutGridAdd />,
    },
    {
      path: "/adminQuizzes",
      name: "Test Series",
      icon: <MdQuiz />,
    },
    {
      path: "/adminAddQuiz",
      name: "Add Test Series",
      icon: <MdQueue />,
    },
    {
      path: "/adminJobs",
      name: "Jobs",
      icon: <IoCloudUploadOutline />,
    },
    {
      path: "/adminResult",
      name: "Result",
      icon: <FaRegCopy />,
    },
    {
      path: "/adminAdmitcard",
      name: "Admit Card",
      icon: <TbBrandAirtable />,
    },
    {
      path: "/adminContact",
      name: "Contacts",
      icon: <MdContacts />,
    },
    // {
    //   path: "/adminallResult",
    //   name: "All Result",
    //   icon: <TbReport/>,
    // },
  ];
  return (
    <div
      className="container"
      style={{ display: "flex", width: "auto", margin: "0px", padding: "0px" }}
    >
      <div style={{ width: isOpen ? "10em" : "3em" }} className="sidebar">
        <div className="top_section">
          {/* <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
            Dashboard
          </h1> */}
          <div style={{ marginLeft: isOpen ? "0px" : "0px" }} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="sidemenulink"
            activeclassname="sidemenulink-active"
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>
      {/* <main>{children}</main> */}
    </div>
  );
};

export default Sidebar;
