import resultServices from '../services/resultServices';
import * as resultConstants from '../constants/resultConstants';

export const fetchResults = async (dispatch, token) => {
    dispatch({ type: resultConstants.FETCH_RESULT_REQUEST });
    const data = await resultServices.fetchResults(token);
    if (data) {
        return dispatch({
            type: resultConstants.FETCH_RESULT_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: resultConstants.FETCH_RESULT_FAILURE,
            payload: data,
        });
    }
};

    export const addResult = async (dispatch, result, token) => {
        dispatch({ type: resultConstants.ADD_RESULT_REQUEST });
        const { data, isAdded, error } = await resultServices.addResult(result, token);
        if (isAdded) {
            return dispatch({
                type: resultConstants.ADD_RESULT_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: resultConstants.ADD_RESULT_FAILURE,
                payload: error,
            });
        }
    };
    export const deleteResult = async (dispatch, resultId, token) => {
        dispatch({ type: resultConstants.DELETE_RESULT_REQUEST });
        const { isDeleted, error } = await resultServices.deleteResult(resultId, token);
        if (isDeleted) {
            return dispatch({
                type: resultConstants.DELETE_RESULT_SUCCESS,
                payload: resultId,
            });
        } else {
            return dispatch({
                type: resultConstants.DELETE_RESULT_FAILURE,
                payload: error,
            });
        }
    };
    export const updateResult = async (dispatch, resId, result, token) => {
        dispatch({ type: resultConstants.UPDATE_RESULT_REQUEST });
        const { data, isUpdated, error } = await resultServices.updateResult(result, resId, token);
        if (isUpdated) {
            return dispatch({
                type: resultConstants.UPDATE_RESULT_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: resultConstants.UPDATE_RESULT_FAILURE,
                payload: error,
            });
        }
    };
    
    export const getResultById = async (dispatch, resId, token) => {
        dispatch({ type: resultConstants.FETCH_RESULT_REQUEST });
        const data = await resultServices.getResultById(resId, token);
        if (data) {
            return dispatch({
                type: resultConstants.FETCH_RESULT_SUCCESS,
                payload: data,
            });
        } else {
            return dispatch({
                type: resultConstants.FETCH_RESULT_FAILURE,
                payload: data,
            });
        }
    }

    
export const fetchResultById = async (dispatch, resultId) => {
    dispatch({ type: resultConstants.FETCH_RESULT_REQUEST });
    const data = await resultServices.getResultById(resultId);
    if (data) {
        return dispatch({
            type: resultConstants.FETCH_RESULT_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: resultConstants.FETCH_RESULT_FAILURE,
            payload: data,
        });
    }
}