import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const fetchQuizzes = async (token, catId) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };

    let quizzes = null;
    if (catId === null) {
      const { data } = await axios.get(`${apiBaseUrl}/api/quiz`);
      quizzes = data;
    } else {
      const { data } = await axios.get(`${apiBaseUrl}/api/quiz/?catId=${catId}`);
      quizzes = data;
    }
    return quizzes;
  } catch (error) {
    console.error(
      "quizzesServices:fetchQuizzes() Error: ",
      error.response.statusText
    );
    return error.response.statusText;
  }
};

const addQuiz = async (quiz, token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    const { data } = await axios.post(`${apiBaseUrl}/api/quiz`, quiz);
    return { data: data, isAdded: true, error: null };
  } catch (error) {
    console.error(
      "quizzesServices:addQuiz()  Error: ",
      error.response.statusText
    );
    return { data: null, isAdded: false, error: error.response.statusText };
  }
};

const deleteQuiz = async (quizId, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.delete(`${apiBaseUrl}/api/quiz/${quizId}/`, config);
    return {
      isDeleted: true,
      error: null,
    };
  } catch (error) {
    console.error(
      "quizzesServices:deleteQuiz()  Error: ",
      error.response.statusText
    );
    return {
      isDeleted: false,
      error: error.response.statusText,
    };
  }
};

const updateQuiz = async (quiz, token) => {
  try {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    // "quiz", quiz.quizId);
    const { data } = await axios.put(`${apiBaseUrl}/api/quiz/${quiz.quizId}/`, quiz);
    return {
      data: data,
      isUpdated: true,
      error: null,
    };
  } catch (error) {
    console.error(
      "quizzesServices:updateQuiz()  Error: ",
      error.response.statusText
    );
    return {
      data: null,
      isUpdated: false,
      error: error.response.statusText,
    };
  }
};

const getQuizById = async (quizId, token) => {
  try {
      const { data } = await axios.get(`${apiBaseUrl}/api/quiz/${quizId}`);
      return data;
  } catch (error) {
      console.error(
      "jobService:getJobsById()  Error: ",
      error.response.statusText
      );
      return error.response.statusText;
  }
  }

const quizzesService = { fetchQuizzes, addQuiz, deleteQuiz, updateQuiz, getQuizById };
export default quizzesService;
