import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import { fetchJobs } from "../../actions/jobsActions";
import { useDispatch, useSelector } from "react-redux";

const Jobs = () => {

  const [jobsData, setJobsData] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
        fetchJobs(dispatch).then((data) => {
          setJobsData(data.payload);
      });
  }, []);

  const columns = [
    // { key: "_id", label: "ID" },
    { key: "title", label: "Job Title" },
    { key: "description", label: "Description" },
  ];

  return (
    <TableComponent
      data={jobsData}
      heading="Apply for Latest Jobs"
      columns={columns}
      name="Apply now"
      link="/jobs-detail"
    />
  );
};

export default Jobs;
