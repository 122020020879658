import React, { useEffect, useState } from "react";
import "./AdminCategoriesPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup } from "react-bootstrap";
import * as categoriesConstants from "../../../constants/categoriesConstants";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import Sidebar from "../../../components/Sidebar";
import {
  deleteCategory,
  fetchCategories,
} from "../../../actions/categoriesActions";
import swal from "sweetalert";

const AdminCategoriesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const [categories, setCategories] = useState([]);

  const categoryClickHandler = (catId) => {
    navigate(`/adminQuizzes/?catId=${catId}`);
  };

  const addNewCategoryHandler = () => {
    navigate("/adminAddCategory");
  };

  const updateCategoryHandler = (event, category) => {
    event.stopPropagation();
    navigate(`/adminUpdateCategory/${category._id}/`);
  };

  const deleteCategoryHandler = (event, category) => {
    event.stopPropagation();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCategory(dispatch, category._id, token).then((data) => {
          if (data.type === categoriesConstants.DELETE_CATEGORY_SUCCESS) {
            swal(
              "Category Deleted!",
              `${category.title} succesfully deleted`,
              "success"
            );
          } else {
            swal(
              "Category Not Deleted!",
              `${category.title} not deleted`,
              "error"
            );
          }
        });
      } else {
        swal(`${category.title} is safe`);
      }
    });
  };

  

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories(dispatch, token).then((data) => {
        setCategories(data.payload);
      });
    }
  }, []);
  // const category = [
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  // ];
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);

  return (
    <div className="adminCategoriesPage__container flex md:flex-row">
      <div className="adminCategoriesPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminCategoriesPage__content ">
        <h2 className="p-3">Categories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-3 p-1 flex flex-col md:flex-row">
          {categories ? (
            categories.length === 0 ? (
              <Message>
                No categories are present. Try adding some categories.
              </Message>
            ) : (
              categories.map((cat, index) => {
                return (
                  <div
                    className="adminCategoriesPage__content--categoryCard mb-3 md:mb-0"
                    key={index}
                  >
                    <ListGroup.Item
                      className="d-flex card-body"
                      onClick={() => categoryClickHandler(cat.catId)}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{cat.title}</div>
                        {cat.description}
                      </div>

                      <div className="flex items-center">
                        <button
                          onClick={(event) => updateCategoryHandler(event, cat)}
                          className="action-button btn btn-success"
                        >
                          {`Update`}
                        </button>

                        <button
                          onClick={(event) => deleteCategoryHandler(event, cat)}
                          className="action-button delete btn btn-danger"
                        >
                          {`Delete`}
                        </button>
                      </div>
                    </ListGroup.Item>
                  </div>
                );
              })
            )
          ) : (
            <Loader />
          )}
        </div>
        <Button
          variant=""
          className="btn btn-success inline p-3 "
          onClick={addNewCategoryHandler}
        >
          Add Exam Category
        </Button>
      </div>
    </div>
  );
};

export default AdminCategoriesPage;
