import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const fetchAdmitCard = async (token) => {
    try {
        // const config = {
        //   headers: { Authorization: `Bearer ${token}` },
        // };
        const { data } = await axios.get(`${apiBaseUrl}/api/admitCard`);
        return data;
    } catch (error) {
        console.error(
        "jobService:fetchJobs() Error: ",
        error.response.statusText
        );
        return error.response.statusText;
    }
    };

const addAdmitCard = async (admitCard, token) => {
    try {
        // const config = {
        //   headers: { Authorization: `Bearer ${token}` },
        // };
        const { data } = await axios.post(`${apiBaseUrl}/api/admitCard`, admitCard);
        return { data: data, isAdded: true, error: null };
    } catch (error) {
        console.error(
        "jobService:addJob() Error: ",
        error.response.statusText
        );
        return { data: null, isAdded: false, error: error.response.statusText };
    }
    };

const deleteAdmitCard = async (admitId, token) => {
    try {
        
        const { data } = await axios.delete(`${apiBaseUrl}/api/admitCard/${admitId}/`);
        return {
        isDeleted: true,
        error: null,
        };
    } catch (error) {
        console.error(
        "jobService:deleteJob()  Error: ",
        error.response.statusText
        );
        return {
        isDeleted: false,
        error: error.response.statusText,
        };
    }
    }

const updateAdmitCard = async (admitCard, admitId, token) => {
    try {
        const config = {
        headers: { Authorization: `Bearer ${token}` },
        };
        const { data } = await axios.put(`${apiBaseUrl}/api/admitCard/${admitId}/`, admitCard, config);
        return { data: data, isUpdated: true, error: null };
    } catch (error) {
        console.error(
        "jobService:updateJob()  Error: ",
        error.response.statusText
        );
        return { data: null, isUpdated: false, error: error.response.statusText };
    }
    }

const getAdmitCardById = async (admitId, token) => {
    try {
        const { data } = await axios.get(`${apiBaseUrl}/api/admitCard/${admitId}`);
        return data;
    } catch (error) {
        console.error(
        "jobService:getJobsById()  Error: ",
        error.response.statusText
        );
        return error.response.statusText;
    }
    }

export default { fetchAdmitCard, addAdmitCard, deleteAdmitCard, updateAdmitCard, getAdmitCardById };
