import React, { useEffect, useState } from "react";
import "../categories/AdminCategoriesPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup } from "react-bootstrap";
import * as admitCardConstants from "../../../constants/admitCardConstants";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import Sidebar from "../../../components/Sidebar";
import {
  deleteAdmitCard,
  fetchAdmitCard,
} from "../../../actions/admitCardActions";
import swal from "sweetalert";

const AdminAdmitCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  // const categoriesReducer = useSelector((state) => state.categoriesReducer);
  // const [categories, setCategories] = useState(categoriesReducer.categories);

  const [admitCard, setAdmitCard] = useState([]);

  const categoryClickHandler = (catId) => {
    navigate(`/adminQuizzes/?catId=${catId}`);
  };

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);
  const addNewAdmitCardHandler = () => {
    navigate("/adminAddAdmitcard");
  };

  const updateAdmitCardHandler = (event, admitCard) => {
    event.stopPropagation();
    navigate(`/adminUpdateAdmitcard/${admitCard._id}/`);
  };

  const deleteAdmitCardHandler = (event, admitCard) => {
    event.stopPropagation();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAdmitCard(dispatch, admitCard._id, token).then((data) => {
          if (data.type === admitCardConstants.DELETE_ADMIT_CARD_SUCCESS) {
            swal(
              "Admit Card Deleted!",
              `${admitCard.title} succesfully deleted`,
              "success"
            );
          } else {
            swal(
              "Admit Card Not Deleted!",
              `${admitCard.title} not deleted`,
              "error"
            );
          }
        });
      } else {
        swal(`${admitCard.title} is safe`);
      }
    });
  };

  

  useEffect(() => {
    if (admitCard.length === 0) {
      fetchAdmitCard(dispatch, token).then((data) => {
        setAdmitCard(data.payload);
      });
    }
  }, []);
  // const category = [
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  //   {
  //     title: "this",
  //     description: "this is the description",
  //   },
  // ];
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);
  return (
    <div className="adminCategoriesPage__container flex md:flex-row">
      <div className="adminCategoriesPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminCategoriesPage__content ">
        <h2 className="p-3">Admit Card</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-3 p-1 flex flex-col md:flex-row">
          {admitCard ? (
            admitCard.length === 0 ? (
              <Message>
                No admit card are present. Try adding some categories.
              </Message>
            ) : (
              admitCard.map((admit, index) => {
                return (
                  <div
                    className="adminCategoriesPage__content--categoryCard mb-3 md:mb-0"
                    key={index}
                  >
                    <ListGroup.Item
                      className="d-flex card-body"
                      onClick={() => categoryClickHandler(admit.catId)}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{admit.title}</div>
                        {admit.description}
                      </div>

                      <div className="flex items-center">
                        <button
                          onClick={(event) => updateAdmitCardHandler(event, admit)}
                          className="action-button btn btn-success"
                        >
                          {`Update`}
                        </button>

                        <button
                          onClick={(event) => deleteAdmitCardHandler(event, admit)}
                          className="action-button delete btn btn-danger"
                        >
                          {`Delete`}
                        </button>
                      </div>
                    </ListGroup.Item>
                  </div>
                );
              })
            )
          ) : (
            <Loader />
          )}
        </div>
        <Button
          variant=""
          className="btn btn-success inline p-3 "
          onClick={addNewAdmitCardHandler}
        >
          Add Admit Card
        </Button>
      </div>
    </div>
  );
};

export default AdminAdmitCard;
