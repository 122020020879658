import * as jobConstnats from '../constants/jobsConstants'
import jobsServices from '../services/jobsServices'

export const addJob = async (dispatch, job, token) => {
    dispatch({ type: jobConstnats.ADD_JOBS_REQUEST });
    const { data, isAdded, error } = await jobsServices.addJob(job, token);
    if (isAdded) {
        return dispatch({
            type: jobConstnats.ADD_JOBS_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: jobConstnats.ADD_JOBS_FAILURE,
            payload: error,
        });
    }
};

export const fetchJobs = async (dispatch, token) => {
    dispatch({ type: jobConstnats.FETCH_JOBS_REQUEST });
    const data = await jobsServices.fetchJobs(token);
    if (data) {
        return dispatch({
            type: jobConstnats.FETCH_JOBS_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: jobConstnats.FETCH_JOBS_FAILURE,
            payload: data,
        });
    }
};
export const getJobs = async (dispatch) => {
    dispatch({ type: jobConstnats.FETCH_JOBS_REQUEST });
    const data = await jobsServices.getjobs();
    if (data) {
        return dispatch({
            type: jobConstnats.FETCH_JOBS_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: jobConstnats.FETCH_JOBS_FAILURE,
            payload: data,
        });
    }
};

export const updateJob = async (dispatch, jobId, job, token) => {
    dispatch({ type: jobConstnats.UPDATE_JOBS_REQUEST });
    const { data, isUpdated, error } = await jobsServices.updateJob(job, jobId, token);
    if (isUpdated) {
        return dispatch({
            type: jobConstnats.UPDATE_JOBS_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: jobConstnats.UPDATE_JOBS_FAILURE,
            payload: error,
        });
    }
};

export const deleteJob = async (dispatch, jobId, token) => {
    dispatch({ type: jobConstnats.DELETE_JOBS_REQUEST });
    const { isDeleted, error } = await jobsServices.deleteJob(jobId, token);
    if (isDeleted) {
        return dispatch({
            type: jobConstnats.DELETE_JOBS_SUCCESS,
            payload: jobId,
        });
    } else {
        return dispatch({
            type: jobConstnats.DELETE_JOBS_FAILURE,
            payload: error,
        });
    }
};

export const fetchJobById = async (dispatch, jobId) => {
    dispatch({ type: jobConstnats.FETCH_JOBS_REQUEST });
    const data = await jobsServices.getJobsBydId(jobId);
    if (data) {
        return dispatch({
            type: jobConstnats.FETCH_JOBS_SUCCESS,
            payload: data,
        });
    } else {
        return dispatch({
            type: jobConstnats.FETCH_JOBS_FAILURE,
            payload: data,
        });
    }
}
