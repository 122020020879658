import React, { useState } from "react";

const AboutUs = () => {
  return (
    <div className="w-full h-full">
      <h1 className="w-full h-20 bg-[#002b50] text-white flex items-center justify-center">
        About Us
      </h1>
      <div className="w-11/12 m-auto">
        <div className="grid grid-cols-2 md:grid-cols-2 my-4 gap-4 items-center">
          {/* Left Side: Text Content */}
          <div>
            <h2 className="text-4xl font-bold mb-4">Who We Are</h2>
            <p className="text-gray-600 mb-4 text-justify">
              This website is provide online mock test for those candidates who
              are preparing for government jobs which provides useful and
              important questions regarding Aptitude, Reasoning, English,
              General Knowledge, Banking Sector, Computer, Interview tips and
              many more. We can say it is a completely useful for all the
              aspirants who is preparing for Competitive Exams like IBPS, RRB,
              SSC etc. Here we provide lots of online tests along with solution
              and report page. Inception of Free online test: Free online test
              is started to provide all the previous year solved questions to
              the aspirants. The main difference between free online test and
              other websites is quality of the questions, easy navigations to
              the links, many numbers of questions are available, time spent on
              each questions, in detailed report page and many more. We are a
              group of people working for this site to help the students who
              will be preparing for competitive exams, or frequently facing
              problems with the exam methodology, time management and the way of
              solving out the answers. We make sure that whoever is attending
              these exams can get a better experience to face the original
              competitive exams. Objectives This website helps poor students
              living in rural and urban areas and preparing for government jobs
              to test and increase their intelligence level for free.
            </p>

            <div className="mt-4 text-justify">
              <h2 className="text-2xl font-bold mb-2">Founders</h2>
              <p>Lt. (Dr.) Bhagwati Prasad Pandit - Founder</p>
              <p>Lt. Mrs. Maya Devi - co-founder</p>
              <p>Mrs. Garima Sharma - President</p>
              <p>
                <strong>Head Office:</strong> Near Sahar Delhi Residency,
                Kuldeep Vihar, Aligarh-202001 (UP)
              </p>
              <p>E-mail: reardy2success@gmail.com</p>
              <p>Contact Number: +919870843098</p>
            </div>
          </div>

          {/* Right Side: Image */}
          <div>
            <img
              src="https://www.shutterstock.com/image-vector/arguing-coworkers-flat-color-vector-600nw-1899090493.jpg"
              alt="About Us"
              className="w-full h-auto rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
