import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const register = async (user) => {
  try {
    const { data } = await axios.post("/api/register", user);
    if (data && data.userId) {
      return { isRegistered: true, error: null };
    } else {
      console.error("authService:register() Error: ", data);
      return { isRegistered: false, error: data };
    }
  } catch (error) {
    console.error("authService:register() Error: ", error.response.statusText);
    return { isRegistered: false, error: error.response.statusText };
  }
};

const login = async (username, password) => {
  try {
    const { data } = await axios.post(`${apiBaseUrl}/api/admin/login`, {
      email: username,
      password: password,
    });
    if (data) {
      localStorage.setItem("user", JSON.stringify(data.admin));
      localStorage.setItem("jwtToken", JSON.stringify(data.token));
      return data;
    } else {
      console.error("authService:login() Error: ", data);
      return data;
    }
  } catch (error) {
    console.error("authService:login() Error: ", error.response.statusText);
    return error.response.statusText;
  }
};

const authServices = { register, login };
export default authServices;
