import * as contactConstants from "../constants/contactConstants";
import { addContact, fetchContacts} from "../services/contactServices";


export const addContacts = async (dispatch, contact) => {
    dispatch({ type: contactConstants.ADD_CONTACT_REQUEST });
    const { data, isAdded, error } = await addContact(contact);
    if (isAdded) {
      return dispatch({
        type: contactConstants.ADD_CONTACT_SUCCESS,
        payload: data,
      });
    } else {
      return dispatch({
        type: contactConstants.ADD_CONTACT_FAILURE,
        payload: error,
      });
    }
  }

export const getContacts = async (dispatch) => {
  dispatch({ type: contactConstants.FETCH_CONTACT_REQUEST });
  const data = await fetchContacts();
  if (data) {
    return dispatch({
      type: contactConstants.FETCH_CONTACT_SUCCESS,
      payload: data,
    });
  } else {
    return dispatch({
      type: contactConstants.FETCH_CONTACT_FAILURE,
      payload: data,
    });
  }
}
